import React from 'react'
import Icon from '../../../ui/Icon/Icon'
import './PhoneChooser.sass'

function PhoneChooser() {
  return (
    <div className="PhoneChooser">
      <Icon name="phone" weight="regular" className="ChooserIcon" />
      <a
        className="PhoneChooser-ActivePhone"
        href="tel: +7 (921) 160-39-00
"
      >
        +7 (921) 160-39-00
      </a>
    </div>
  )
}

export default PhoneChooser
