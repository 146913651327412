import React from 'react'
import './ContactUs.sass'
import { YMaps, Map, Placemark } from 'react-yandex-maps'
import Icon from '../../ui/Icon/Icon'
import SectionTitle from '../../ui/SectionTitle/SectionTitle'
import ContactUsForm from './ContactUsForm/ContactUsForm'
import ContactUsPopUp from './ContactUsPopUp'

function ContactUs() {
  return (
    <section id="contacts" className="ContactUs DefaultSection">
      <ContactUsPopUp />
      <div className="container">
        <div className="row">
          <div className="col-12">
            <SectionTitle title="Контакты" />
            <div className="ContactUs-Container">
              <div className="ContactUs-Info">
                <div className="ContactUs-Links">
                  <ContactUsInfoTile
                    icon="mobile"
                    data={
                      <>
                        <a href="tel:+7 (921) 160-39-00">+7 (921) 160-39-00</a>
                      </>
                    }
                    description="Viber, Telegram, Whatsapp"
                  />
                  <ContactUsInfoTile
                    icon="envelope"
                    data="hvoya.midl@gmail.com"
                  />
                  <ContactUsInfoTile icon="clock" data="Круглосуточно" />
                  <ContactUsInfoTile
                    icon="map-marker-alt"
                    data="г. Мурманск, ул. Шмидта, д. 17"
                  />
                </div>
                <div className="DocumentsLinks">
                  <a href="/docs/sout.pdf" target="blank">
                    СОУТ
                  </a>
                  <a href="/docs/stars.pdf" target="blank">
                    Свидетельство о присвоении категории
                  </a>
                </div>
                <div className="ContactUs-Map">
                  <YMaps>
                    <Map
                      className="Map"
                      defaultState={{
                        center: [68.95923204942511, 33.06397649999999],
                        zoom: 16,
                      }}
                    >
                      <Placemark
                        geometry={[68.95923204942511, 33.06397649999999]}
                        options={{ preset: 'islands#greenIcon' }}
                      />
                    </Map>
                  </YMaps>
                </div>
              </div>
              {/* <ContactUsForm /> */}
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

function ContactUsInfoTile({ icon, data, description }) {
  return (
    <div className="ContactUsInfoTile">
      <div className="ContactUsInfoTile-Icon">
        <Icon name={icon} weight="solid" />
      </div>
      <div className="ContactUsInfoTile-Data">
        <p className="Data">{data}</p>
        {description && <p className="Description">{description}</p>}
      </div>
    </div>
  )
}
export default ContactUs
